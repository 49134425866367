import { RPA1InputFieldModel } from './rpa1InputFields.model';
import { Utils } from '../../services/Utils';

export class RPA1DictonaryModel {

  title: string;
  instructionsTitle: string;
  instructionsP1: string;
  instructionsP2: string;
  instructionsP3: string;
  instructionsP4: string;
  buttonDownload: string;
  buttonStart: string;
  buttonSubmit: string;
  congratulations: string;
  completedMessage:string;
  inputFields: RPA1InputFieldModel;

  constructor(data?: JSON) {
    if (data === undefined || data === null) {
      throw new Error('Undefined data');
    }
    this.title = Utils.getParam(data, 'title');
    this.instructionsTitle = Utils.getParam(data, 'instructionsTitle');
    this.instructionsP1 = Utils.getParam(data, 'instructionsP1');
    this.instructionsP2 = Utils.getParam(data, 'instructionsP2');
    this.instructionsP3 = Utils.getParam(data, 'instructionsP3');
    this.instructionsP4 = Utils.getParam(data, 'instructionsP4');
    this.buttonDownload = Utils.getParam(data, 'buttonDownload');
    this.buttonStart = Utils.getParam(data, 'buttonStart');
    this.buttonSubmit = Utils.getParam(data, 'buttonSubmit');
    this.inputFields = Utils.getParam(data, 'inputFields');
    this.completedMessage = Utils.getParam(data, 'completedMessage');
    this.congratulations = Utils.getParam(data, 'congratulations');
  }
}


