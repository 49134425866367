import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RPA1DictonaryModel } from '../components/models/rpa1Dictonary.model';
import { map } from 'rxjs/operators';
import { RPA1InputFieldModel } from '../components/models/rpa1InputFields.model';
import jsonData from './../../assets/downloadFiles/rpa1_dictionary.json';

@Injectable()
export class RPA1_DictionaryService {

    dictionary: any;
    lng: String = 'EN';
    // url = 'http://uipath509.westeurope.cloudapp.azure.com:4444/api/v1/rpa1_dictionary/';
    constructor(private http: HttpClient) {
    }

    public getLanguage(){
        return this.lng;

    }

    public setLanguage(lng){
        this.lng = lng;
    }

    // public setDictonary(): Promise<any> {
    //     return this.http
    //     .get(this.url + this.lng)
    //         .toPromise()
    //         .then(
    //              this.extractData,
    //              this.dictionary = this.extractData )
    //         .catch(this.handleError);
    // }

    public getDictonary() {
      return this.extractData(jsonData.find(item => item.language === this.lng));
    }

    // private extractData(resp: Response) {
      private extractData(resp) {
        const inputFields: any = new RPA1InputFieldModel(
            <JSON>({
                labelCompanyName: resp['labelCompanyName'],
                labelFirstName: resp['labelFirstName'],
                labelLastName: resp['labelLastName'],
                labelRole: resp['labelRole'],
                labelAddress:  resp['labelAddress'],
                labelPhone: resp['labelPhone'],
                labelEmail: resp['labelEmail']
            }as any)
        );
        const dictionary: any = new RPA1DictonaryModel(
            <JSON>({
                title: resp['title'],
                instructionsTitle: resp['instructionsTitle'],
                instructionsP1: resp['instructionsP1'],
                instructionsP2: resp['instructionsP2'],
                instructionsP3: resp['instructionsP3'],
                instructionsP4: resp['instructionsP4'],
                buttonDownload: resp['buttonDownload'],
                buttonStart: resp['buttonStart'],
                buttonSubmit: resp['buttonSubmit'],
                congratulations : resp['congratulations'],
                completedMessage : resp['completedMessage'],
                inputFields: inputFields
            } as any)
        );

        return dictionary;
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }



}
