import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rpa2',
  templateUrl: './rpa2.component.html',
  styleUrls: ['./rpa2.component.css']
})
export class Rpa2Component implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href='./../assets/academicalliance/watchmovie.html';
  }

}
