export class RPA1Field{
    label: string;
    dictionaryValue:string;
    id: string;

    constructor(
        label: string,
        dictionaryValue:string,
        id: string){
        this.label=label;
        this.dictionaryValue=dictionaryValue;
        this.id=id;
    }

}