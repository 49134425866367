export class RPA1Person{
    firstName: string;
    lastName:string;
    companyName: string;
    role:string;
    address: string;
    email:string;
    phone:string;
    

    constructor(
        firstName: string,
        lastName:string,
        companyName: string,
        role:string,
        address: string,
        email:string,
        phone:string,
    ){
        this.firstName=firstName;
        this.lastName=lastName;
        this.companyName=companyName;
        this.role=role;
        this.address=address;
        this.email=email;
        this.phone=phone;
    }
}
