import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rpa4',
  templateUrl: './rpa4.component.html',
  styleUrls: ['./rpa4.component.css']
})
export class Rpa4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
