import { Component, OnInit } from '@angular/core';
import { RPA3_MovieService } from '../../services/rpa3_movies.service';
import { EventEmitter } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { RPA3Review } from '../models/rpa3Review.model';
import { getRandomString } from 'selenium-webdriver/safari';
@Component({
  selector: 'app-rpa3',
  templateUrl: './rpa3.component.html',
  styleUrls: ['./rpa3.component.css']
})
export class Rpa3Component {
  popularList: Array<Object>;
  theatersList: Array<Object>;
  searchStr: string;
  searchRes: Array<Object>;
  movieClicked: Object;
  reviews: RPA3Review[] = [];
  description: string;
  movieList: any;
  searchMovie: boolean = true;
  myList: Array<Object> = [];
  buttonStartEnabled = false;
  modalActions = new EventEmitter<string | MaterializeAction>();
  startTime: any;

  reviewDone: boolean;
  instruction1: string;
  instruction2: string;
  instruction3: string;
  instruction4: string;
  reviewsClicked: String[]= ['false','false','false','false','false','false','false','false','false','false','false','false','false','false','false'];

  partialScore:number =0;
  totalScore: number = 0;
  congratulations :string;
  completedMessage :string;
  moviePos:any =0;;


  constructor(private movieSrv: RPA3_MovieService) {
    this.instruction1 = 'The goal of this challenge is to create a workflow that will check which movie review is positive or negative.';
    this.instruction2 = 'Add 3 movies to your list in order to start the challenge. You can either search for them or get a list of 3 popular movies';
    this.instruction3 = 'Click on each movie in your Movie List in order to see the reviews. Check each review and see if it is positive or negative. Once you have checked all the reviews, press on Submit in order to see your score.';
    this.congratulations='Congratulations'
    this.completedMessage ='Your success rate is xxx% in yyy milliseconds'
  }

  addToMyList(movie) {
    if (!this.myList.some((movieId) => movieId['title'] == movie.title ) ) {
      this.myList.push(movie);
    }
  }

  getPopular() {
    this.searchRes = null;
    this.movieSrv.getPopular().subscribe(res => {
      this.searchRes = this.shuffleArray(res.results).slice(0, 3);
      this.myList = this.searchRes;
    });
  }

  searchMovies() {
    this.searchRes = null;
    this.movieSrv.searchMovies(this.searchStr).subscribe(res => {
      this.searchRes = res.results.slice(0, 3);
      this.reviews = null;
    });
  }

  deleteFromList(id) {
    this.myList.splice(this.myList.indexOf(this.myList[id]), 1);
  }

  getReviews(id) {
    this.moviePos=id;
    this.movieClicked = this.myList[id];
    this.reviews = [];
    this.movieSrv.getPositiveReviews().then(res => {
      for (let idx = 0; idx < res.length; idx++) {
        this.reviews.push(new RPA3Review('1', res[idx].content.toString().replace(new RegExp('<br />', 'g'), '')));
      }
      const randNr =Math.floor(Math.random() * 5);

      this.reviews = this.reviews.slice(0,randNr);

      this.movieSrv.getNegativeReviews().then(res => {
        for (let idx = this.reviews.length; idx < 5; idx++) {
          this.reviews.push(new RPA3Review('0', res[idx].content.toString().replace(new RegExp('<br />', 'g'), '')));
        }
        this.reviews = this.shuffleArray(this.reviews)
        console.log(this.reviews)
        this.openModal();
      });
    });



  }

  openModal() {
    this.modalActions.emit({ action: 'modal', params: ['open'] });
    //console.log(this.reviewsClicked)
  }
  closeModal() {
    this.modalActions.emit({ action: 'modal', params: ['close'] });

  }

  reviewClicked(id, review, movieTitle) {

    let idx= this.myList.findIndex( movie => movie['title']===movieTitle);
    this.reviewsClicked[id+idx*5]='true';
    console.log(this.reviews[id].type)
    if (review == this.reviews[id].type && this.buttonStartEnabled == true) {

      this.totalScore++;
    }

  }

  shuffleArray(inputArray) {
    let len = inputArray.length;
    let ret = inputArray.slice();
    while (len) {
      let rand = Math.floor(Math.random() * len--);
      let tmp = ret[len];
      ret[len] = ret[rand];
      ret[rand] = tmp;
    }
    return ret;
  }

  start() {
    if (this.myList.length === 3) {
      this.buttonStartEnabled = true;
      this.reviewsClicked= ['false','false','false','false','false','false','false','false','false','false','false','false','false','false','false'];
      this.startTime = Date.now();
    }
  }

  submit(){
    this.searchMovie=false;
    let counter=Date.now() - this.startTime;
    let succesRate=this.totalScore/15*100;
    this.completedMessage=this.completedMessage.replace('xxx', succesRate + '');
    this.completedMessage=this.completedMessage.replace('yyy', counter + '');
    console.log(this.completedMessage)
    console.log(counter)
  }

}
