import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from './app.component';

import { Rpa1Component } from './components/rpa1/rpa1.component';

import { Rpa3Component } from './components/rpa3/rpa3.component';
import { Rpa2Component } from './components/rpa2/rpa2.component';


const APP_ROUTES: Routes = [
  { path : '', component: Rpa1Component },
  { path: 'home', component: Rpa1Component },
  { path: 'movieSearch', component: Rpa3Component },
  { path: 'academicalliance/watchmovie', component: Rpa2Component },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
