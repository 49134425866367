export class Utils {

    static getParam ( data: JSON, paramName: string, defaultValue?: any ) {
        if ( data.hasOwnProperty( paramName )) {
            return data[paramName];
        }
        return defaultValue;
    }

    static getParamFromObject ( data: any, paramName: string, defaultValue?: any ) {
        if (data === undefined || data === null) {
            return defaultValue;
        }
        if ( data.hasOwnProperty( paramName )) {
            return data[paramName];
        }
        return defaultValue;
    }

}
