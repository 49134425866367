import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Jsonp} from '@angular/http';

import 'rxjs/Rx';


@Injectable()
export class RPA3_MovieService{
    apikey: string;

    constructor(private http: HttpClient, private _jsonp:Jsonp){
        this.apikey = '014c5ff2b83dc17257629894b0fef1fb';
        console.log('MovieService Initialized...');
    }

    getPopular(){
        return this._jsonp.get('https://api.themoviedb.org/3/discover/movie?callback=JSONP_CALLBACK&sort_by=popularity.desc&api_key='+this.apikey)
            .map(res => res.json());
    }

    searchMovies(searchStr:string){
        return this._jsonp.get('https://api.themoviedb.org/3/search/movie?callback=JSONP_CALLBACK&query='+searchStr+'&sort_by=popularity.desc&api_key='+this.apikey)
            .map(res => res.json());
    }

    getMovie(id:string){
        return this._jsonp.get('https://api.themoviedb.org/3/movie/'+id+'?callback=JSONP_CALLBACK&api_key='+this.apikey)
            .map(res => res.json());
    }

    getPositiveReviews(){
        return this.http
        .get("http://uipath509.westeurope.cloudapp.azure.com:4444/api/v1/rpa3_reviewsPos")
        .toPromise()
        .then(
            resp=> { return resp;})
        .catch(this.handleError);
    }

    getNegativeReviews(){
        return this.http
        .get("http://uipath509.westeurope.cloudapp.azure.com:4444/api/v1/rpa3_reviewsNeg")
        .toPromise()
        .then(
            resp => {
              return resp;
            })
        .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }


}
