import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import {HttpModule, JsonpModule} from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MaterializeModule } from "angular2-materialize";

import 'materialize-css';

import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { Rpa1Component } from './components/rpa1/rpa1.component';
import { Rpa2Component } from './components/rpa2/rpa2.component';
import { Rpa3Component } from './components/rpa3/rpa3.component';
import { Rpa4Component } from './components/rpa4/rpa4.component';
import { RPA1_DictionaryService } from './services/rpa1_dictionary.service';
import { CheckFieldService } from './services/rpa1_checkFields.service';
import { RPA3_MovieService } from './services/rpa3_movies.service';
import { Rpa1FieldComponent } from './components/rpa1/rpa1-field/rpa1-field.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Rpa1Component,
    Rpa2Component,
    Rpa3Component,
    Rpa4Component,
    Rpa1FieldComponent,

  ],
  imports: [
    SelectDropDownModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    JsonpModule,
    ReactiveFormsModule,
    MaterializeModule
  ],
  providers: [
    RPA1_DictionaryService,
    CheckFieldService,
    RPA3_MovieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
