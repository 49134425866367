import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RPA1Person } from '../components/models/rpa1Person.model';

@Injectable()
export class CheckFieldService {
  public inputPersons: RPA1Person[] = [];
  totalScore = 0;

  constructor(private _activatedRoute: ActivatedRoute) {
    this.inputPersons = [];
    let selectedLanguage = '';

    _activatedRoute.queryParams.subscribe((params: String[]) => {
      if (params['lang']) {
        selectedLanguage = params['lang'].toString().toUpperCase();
        if (selectedLanguage.toString().toUpperCase() === 'JA') {
          this.changeLanguageToJA();
        } else {
          this.changeLanguageToEN();
        }
      } else {
        selectedLanguage = 'EN';
        this.changeLanguageToEN();
      }

    });
}

 returnScore(){
   return this.totalScore;
 }

  checkPerson(checkPerson: RPA1Person, idx) {
    if (checkPerson.firstName === this.inputPersons[idx].firstName){
      this.totalScore++;
    }
    if (checkPerson.lastName === this.inputPersons[idx].lastName){
      this.totalScore++;
    }
    if ( checkPerson.email === this.inputPersons[idx].email){
      this.totalScore++;
    }
    if ( checkPerson.address === this.inputPersons[idx].address){
      this.totalScore++;
    }
    if ( checkPerson.companyName === this.inputPersons[idx].companyName){
      this.totalScore++;
    }
    if ( checkPerson.phone === this.inputPersons[idx].phone){
      this.totalScore++;
    }
    if ( checkPerson.role === this.inputPersons[idx].role){
      this.totalScore++;
    }

  }

  changeLanguageToJA() {

    this.inputPersons.push(
      new RPA1Person(
        '隆司',
        '井上',
        '東京千代田（株）',
        '財務部',
        '東京都',
        'takashi.j@itsolutions.co.uk',
        '40791345621'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        '信弘',
        '佐藤',
        '（株）北海札幌',
        '人事部',
        '北海道',
        's.shin@mc.com',
        '40722345600'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        '美佐子',
        '林',
        '九州福岡（株）',
        '情報部',
        '福岡',
        'misa.hayashi@waterfront.com',
        '40735416854'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        '理恵子',
        '花田',
        '（株）香川松山',
        '営業',
        '香川',
        'h.rie@mc.com',
        '40733652145'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'りさ',
        '佐藤',
        '（株）大阪難波',
        'ITソリューション',
        '大阪',
        'r-sato@timepath.co.uk',
        '40799885412'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        '優斗',
        '町田',
        '愛知名古屋（株）',
        '代理店',
        '名古屋',
        'you.mati@aperture.us',
        '40733154268'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'ハマー',
        '高橋',
        '（株）秋田海産',
        'アドバイザー',
        '秋田',
        'h.hama@sugarwell.org',
        '40712462257'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'ロドリゲス',
        'シェルビー',
        '（株）富山商店',
        '業務部',
        '富山',
        's-rodo@aperture.us',
        '40731254562'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        '美恵',
        '那須',
        '（株）高知松山',
        '総務部',
        '高知',
        'na_emi@techdev.com',
        '40741785214'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'パーマー',
        'ララ',
        '（株）長野信越',
        '情報システム',
        '長野',
        'rara.p@timepath.co.uk',
        '40731653845'
      )
    );
  }

  changeLanguageToEN() {
    this.inputPersons.push(
      new RPA1Person(
        'John',
        'Smith',
        'IT Solutions',
        'Analyst',
        '98 North Road',
        'jsmith@itsolutions.co.uk',
        '40716543298'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Jane',
        'Dorsey',
        'MediCare',
        'Medical Engineer',
        '11 Crown Street',
        'jdorsey@mc.com',
        '40791345621'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Albert',
        'Kipling',
        'Waterfront',
        'Accountant',
        '22 Guild Street',
        'kipling@waterfront.com',
        '40735416854'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Michael',
        'Robertson',
        'MediCare',
        'IT Specialist',
        '17 Farburn Terrace',
        'mrobertson@mc.com',
        '40733652145'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Doug',
        'Derrick',
        'Timepath Inc.',
        'Analyst',
        '99 Shire Oak Road',
        'dderrick@timepath.co.uk',
        '40799885412'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Jessie',
        'Marlowe',
        'Aperture Inc.',
        'Scientist',
        '27 Cheshire Street',
        'jmarlowe@aperture.us',
        '40733154268'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Stan',
        'Hamm',
        'Sugarwell',
        'Advisor',
        '10 Dam Road',
        'shamm@sugarwell.org',
        '40712462257'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Michelle',
        'Norton',
        'Aperture Inc.',
        'Scientist',
        '13 White Rabbit Street',
        'mnorton@aperture.us',
        '40731254562'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Stacy',
        'Shelby',
        'TechDev',
        'HR Manager',
        '19 Pineapple Boulevard',
        'sshelby@techdev.com',
        '40741785214'
      )
    );
    this.inputPersons.push(
      new RPA1Person(
        'Lara',
        'Palmer',
        'Timepath Inc.',
        'Programmer',
        '87 Orange Street',
        'lpalmer@timepath.co.uk',
        '40731653845'
      )
    );
  }

}
