import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { RPA1_DictionaryService } from '../../services/rpa1_dictionary.service';

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FormGroup, NgForm, FormControl, Validators, FormBuilder } from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';
import { RPA1DictonaryModel } from '../models/rpa1Dictonary.model';
import { RPA1Field } from '../models/rpa1Fied.model';
import { RPA1Person } from '../models/rpa1Person.model';
import { Rpa1FieldComponent } from './rpa1-field/rpa1-field.component';
import { CheckFieldService } from 'src/app/services/rpa1_checkFields.service';


@Component({
  selector: 'app-rpa1',
  templateUrl: './rpa1.component.html',
  styleUrls: ['./rpa1.component.css'],
})

export class Rpa1Component implements OnInit {

  @ViewChildren(Rpa1FieldComponent) fields: QueryList<Rpa1FieldComponent>;

  public inputForm: FormGroup;
  inputFields: RPA1Field[] = [];

  public lang = ['EN', 'RO', 'JA'];
  public selectedLanguage: String = 'EN';
  public dictionary: RPA1DictonaryModel;
  public resp: any;
  public title: string;
  public buttonStart: string;
  public loaded: Boolean = false;
  public colIdx = 6;
  public idx = 1;
  public position = ['left', 'right'];
  public idxPosition = 0;
  public challengeCompleted = false;
  public counter: number;
  public startTime: any;
  public correctInputs: number = 0;
  public completedMessage: string;
  public labelAddress = 'aa';
  public labelRole;
  public langLink: String = '';


  searchConfig = {
    displayKey: 'description',
    search: true,
    height: 'auto',
    placeholder: this.selectedLanguage,
    customComparator: () => { },
    limitTo: this.lang.length,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name'
  }

  constructor(
    private rpa_dictionaryService: RPA1_DictionaryService,
    private rpa_checkFields: CheckFieldService,
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private _router: Router) {

    _activatedRoute.queryParams.subscribe(params => {
      if (params['lang']) {
        this.selectedLanguage = params['lang'].toString().toUpperCase();
        rpa_dictionaryService.setLanguage(this.selectedLanguage);

      } else {
        this.selectedLanguage = 'EN';
      }
    });
  }

  ngOnInit() {
    this.getDictonary();
    this.inputForm = new FormGroup({
      labelAddress: new FormControl(null, Validators.required),
      labelCompanyName: new FormControl(null, Validators.required),
      labelEmail: new FormControl(null, Validators.required),
      labelFirstName: new FormControl(null, Validators.required),
      labelLastName: new FormControl(null, Validators.required),
      labelPhone: new FormControl(null, Validators.required),
      labelRole: new FormControl(null, Validators.required)
    });

  }

  getDictonary() {
    this.rpa_dictionaryService.getDictonary();
    // this.rpa_dictionaryService.setDictonary()
    //   .then(result => {
        this.dictionary = this.rpa_dictionaryService.getDictonary();
        this.loaded = true;
        this.generateFields();
        this.buttonStart = this.dictionary.buttonStart;
      // })
      // .catch(error => console.log(error));


    if (this.selectedLanguage === 'JA') {
      this.langLink = '/assets/downloadFiles/challenge_ja.xlsx';
    } else {
      this.langLink = './assets/downloadFiles/challenge.xlsx';
    }
  }

  onSubmit() {


    this.colIdx = Math.floor(Math.random() * 12) + 3;
    this.idxPosition = Math.floor(Math.random() * 2) + 0;
    if ((this.idx >= 1) && (this.idx <= 10) && this.startTime) {

      this.idx++;
      this.buttonStart = 'Round ' + this.idx;

      const person = new RPA1Person(
        this.fields.filter(value => value.label === 'labelFirstName')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelLastName')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelCompanyName')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelRole')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelAddress')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelEmail')[0].sendValue(),
        this.fields.filter(value => value.label === 'labelPhone')[0].sendValue());

        this.rpa_checkFields.checkPerson(person, this.idx - 2);
      }
    if (this.idx === 11) {
      this.idx++;
      this.buttonStart = 'Reset';
      this.challengeCompleted = true;
      this.counter = Date.now() - this.startTime;

      this.completedMessage = this.dictionary.completedMessage.replace('zzz', this.rpa_checkFields.returnScore() + '' );
      this.correctInputs = this.rpa_checkFields.returnScore() / 70 * 100;
      this.completedMessage = this.completedMessage.replace('xxx', this.correctInputs + '%');

      this.completedMessage = this.completedMessage.replace('yyy', this.counter.toString());
    }


    this.inputForm.reset();
    this.generateFields();

  }

  start() {
    if (this.buttonStart === 'Reset'){
      this.idx = 0;
      window.location.reload();
    }
    // if (this.idx === 12) {
    //   this.idx = 1;
    //   this.challengeCompleted = false;
    //   this.correctInputs = 0;
    //   this.start();
    //   this.startTime = Date.now();
    // }
    this.buttonStart = 'Round ' + this.idx;
    this.inputForm.reset();
    this.generateFields();
    this.startTime = Date.now();

  }

  selectionChanged(lng) {
    if (!lng) {
      this.selectedLanguage = 'EN';
      this.rpa_dictionaryService.setLanguage('EN');
    }
    else {
      this.selectedLanguage = lng;
      this.rpa_dictionaryService.setLanguage(lng);
    }
    this.getDictonary();
    this._router.navigate([''], { queryParams: { lang: lng } });
  }



  generateFields() {
    this.inputFields = [];
    this.inputFields.push(new RPA1Field('labelAddress', this.dictionary.inputFields.labelAddress, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelCompanyName', this.dictionary.inputFields.labelCompanyName, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelEmail', this.dictionary.inputFields.labelEmail, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelFirstName', this.dictionary.inputFields.labelFirstName, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelLastName', this.dictionary.inputFields.labelLastName, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelPhone', this.dictionary.inputFields.labelPhone, this.generateRandomId()));
    this.inputFields.push(new RPA1Field('labelRole', this.dictionary.inputFields.labelRole, this.generateRandomId()));

    this.inputFields = this.shuffleArray(this.inputFields)
  }

  shuffleArray(inputArray) {
    let len = inputArray.length;
    let ret = inputArray.slice();
    while (len) {
      let rand = Math.floor(Math.random() * len--);
      let tmp = ret[len];
      ret[len] = ret[rand];
      ret[rand] = tmp;
    }
    return ret;
  }

  generateRandomId() {
    let text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 5; i++){
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }




}
