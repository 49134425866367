import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'rpa1-field',
  templateUrl: './rpa1-field.component.html',
  styleUrls: ['./rpa1-field.component.css']
})
export class Rpa1FieldComponent implements OnInit {

  @Input() id: string;
  @Input() dictionaryValue: string;
  @Input() label: string;
  @Input() parentFormGroup: any;

  @ViewChild('value') input:any; 
  constructor() { }

  ngOnInit() {
  }

  sendValue(){
   return this.input.nativeElement.value;
  }

}
