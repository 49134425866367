import { Utils } from '../../services/Utils';

export class RPA1InputFieldModel {
  labelAddress: string;
  labelCompanyName: string;
  labelEmail: string;
  labelFirstName: string;
  labelLastName: string;
  labelPhone: string;
  labelRole: string;

  constructor(data?: JSON) {
   
    if (data === undefined || data === null) {
      throw new Error('Undefined data');
    }

    this.labelAddress = Utils.getParam(data, 'labelAddress');
    this.labelCompanyName = Utils.getParam(data, 'labelCompanyName');
    this.labelEmail = Utils.getParam(data, 'labelEmail');
    this.labelFirstName = Utils.getParam(data, 'labelFirstName');
    this.labelLastName = Utils.getParam(data, 'labelLastName');
    this.labelPhone = Utils.getParam(data, 'labelPhone');
    this.labelRole = Utils.getParam(data, 'labelRole');

  }
}
